@use "src/styles/variables.scss" as variables;

.Button {
    height: 40px;
    padding: 0 30px;
    background-color: variables.$orange;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border-bottom: 3px solid darken(variables.$orange, 20%);
    color: variables.$white;
    cursor: pointer;
    font-weight: 400;

    // &:hover {
    //     margin-top: 1px;
    //     border-bottom-width: 2px;
    // }
}