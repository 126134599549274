@use "src/styles/variables.scss" as variables;

@keyframes intro {
    from {
        opacity: 0;
        margin-top: -30px;
    }
    to {
        opacity: 1;
        margin-top: 0px;
    }
}

.header {
    display: flex;
    height: 80px;
    align-items: center;
    justify-content: space-between;
    animation-name: intro;
    animation-duration: 0.5s;

    @media screen and (max-width: variables.$width-mobile) {
        flex-direction: column;
        animation-name: none;
    }


    h1 {
        margin-left: 32px;
        font-size: 32px;
        color: variables.$dark-grey;

        @media screen and (max-width: variables.$width-mobile) {
            margin-left: 0px;
            margin-top: 20px;
        }
    }

    .button-area {
        display: flex;
        margin-right: 32px;
        justify-content: space-between;
        gap: 16px;

        @media screen and (max-width: variables.$width-mobile) {
            margin-right: 0px;
            margin-top: 20px;
        }
    
    }
}